{
  "locale": "hi",
  "countries": {
    "AD": "अंडोरा",
    "AE": "संयुक्त अरब अमीरात",
    "AF": "अफगानिस्तान",
    "AG": "एंटीगुआ और बारबूडा",
    "AI": "अंग्विला",
    "AL": "अल्बानिया",
    "AM": "अर्मेनिया",
    "AO": "अंगोला",
    "AQ": "अंटार्टिका",
    "AR": "अर्जेंटिना",
    "AS": "अमेरिकान सामोआ",
    "AT": "आस्ट्रिया",
    "AU": "आस्ट्रेलिया",
    "AW": "अरुबा",
    "AX": "ऑलैण्ड द्वीपसमूह",
    "AZ": "अजरबेजान",
    "BA": "बोस्निया हर्जेगोविना",
    "BB": "बार्बाडोस",
    "BD": "बांग्लादेश",
    "BE": "बेल्जियम",
    "BF": "बुर्किना फासो",
    "BG": "बल्गारिया",
    "BH": "बहरीन",
    "BI": "बुरुंडी",
    "BJ": "बानिन",
    "BL": "सेंट_बार्थेलेमी",
    "BM": "बर्मूडा",
    "BN": "ब्रुनेई",
    "BO": "बोलिविया",
    "BQ": "कैरिबियन नीदरलैंड",
    "BR": "ब्राजील",
    "BS": "बहामास",
    "BT": "भूटान",
    "BV": "बाउवेट",
    "BW": "बोत्सवाना",
    "BY": "बेलारुस",
    "BZ": "बेलिजे",
    "CA": "कनाडा",
    "CC": "कोकोस (कीलिंग) द्वीप",
    "CD": "कांगो लोकतान्त्रिक गणराज्य",
    "CF": "सेंट्रल अफ्रीका गणतंत्र",
    "CG": "कांगो",
    "CH": "स्विट्जरलैंड",
    "CI": "आइवरी कोस्ट",
    "CK": "कुक द्वीप",
    "CL": "चिली",
    "CM": "कैमरून",
    "CN": "चीन",
    "CO": "कोलंबिया",
    "CR": "कोस्टा रिका",
    "CU": "क्यूबा",
    "CV": "केप वर्दे",
    "CW": "कुराकाओ",
    "CX": "क्रिसमस द्वीप",
    "CY": "साइप्रस",
    "CZ": "चेक",
    "DE": "जर्मनी",
    "DJ": "जिबॉती",
    "DK": "डैनमार्क",
    "DM": "डोमिनिक",
    "DO": "डोमिनिक गणतंत्र",
    "DZ": "अल्जीरिया",
    "EC": "इक्वाडोर",
    "EE": "एस्तोनिया",
    "EG": "मिस्र",
    "EH": "पश्चिमी सहारा",
    "ER": "एरिट्रेया",
    "ES": "स्पेन",
    "ET": "इथियोपिया",
    "FI": "फिनलैंड",
    "FJ": "फिजी",
    "FK": "फाकलैंड द्वीप समूह (मालविनास)",
    "FM": "माइक्रोनेशिया",
    "FO": "फराओ द्वीप समूह",
    "FR": "फ्रांस",
    "GA": "गैबोन",
    "GB": "ग्रेट ब्रिटेन",
    "GD": "ग्रेनेडा",
    "GE": "जॉर्जिया",
    "GF": "फ्रेंच गुआना",
    "GG": "ग्वेर्नसे",
    "GH": "घाना",
    "GI": "जिब्राल्टर",
    "GL": "ग्रीनलैंड",
    "GM": "गाम्बिया",
    "GN": "गिनी",
    "GP": "ग्वाडेलोप",
    "GQ": "एक्वेटोरियल गिनी",
    "GR": "यूनान",
    "GS": "दक्षिण जॉर्जिया और दक्षिण सैंडविच द्वीप समूह",
    "GT": "ग्वाटेमाला",
    "GU": "ग्वाम",
    "GW": "गिनी-बिसाउ",
    "GY": "गुआना",
    "HK": "हांग कांग",
    "HM": "हर्ड एंड मैकडोनाल्ड द्वीपसमूह",
    "HN": "होंडुरास",
    "HR": "क्रोएशिया",
    "HT": "हैती",
    "HU": "हंगरी",
    "ID": "इंडोनेशिया",
    "IE": "आयरलैंड",
    "IL": "इजराइल",
    "IM": "आइसल ऑफ मैन",
    "IN": "भारत",
    "IO": "ब्रितानी हिंद महासागरीय क्षेत्र",
    "IQ": "इराक",
    "IR": "ईरान",
    "IS": "आइसलैंड",
    "IT": "इटली",
    "JE": "जर्सी",
    "JM": "जमैका",
    "JO": "जॉर्डन",
    "JP": "जापान",
    "KE": "केन्या",
    "KG": "किर्जिस्तान",
    "KH": "कंबोडिया",
    "KI": "किरिबिती",
    "KM": "कोमोरोस",
    "KN": "सेंट किट्स एंड नेविस",
    "KP": "उत्तर कोरिया",
    "KR": "दक्षिण कोरिया",
    "KW": "कुवैत",
    "KY": "केमैन आइसलैंड्स",
    "KZ": "कज़ाखिस्तान",
    "LA": "लाओस",
    "LB": "लेबनान",
    "LC": "सेंट लुसिया",
    "LI": "लिक्टेनिस्टीन",
    "LK": "श्री लंका",
    "LR": "लाइबेरिया",
    "LS": "लेसोथो",
    "LT": "लिथुआनिया",
    "LU": "लक्जमबर्ग",
    "LV": "लात्विया",
    "LY": "लीबिया",
    "MA": "मोरक्को",
    "MC": "मोनाको",
    "MD": "मोलदोवा",
    "ME": "मोंटेनेग्रो",
    "MF": "सेंट मार्टिन की सामूहिकता",
    "MG": "मैडागास्कर",
    "MH": "मार्शल द्वीपसमूह",
    "MK": "मकदूनिया",
    "ML": "माली",
    "MM": "म्यामांर (बर्मा)",
    "MN": "मंगोलिया",
    "MO": "मकाओ",
    "MP": "उत्तर मैरिना द्वीपसमूह",
    "MQ": "मार्टिनिक",
    "MR": "मॉरितानिया",
    "MS": "मोंटसेराट",
    "MT": "माल्टा",
    "MU": "मारीशस",
    "MV": "मालदीव",
    "MW": "मालावी",
    "MX": "मेक्सिको",
    "MY": "मलयेशिया",
    "MZ": "मोजांबिक",
    "NA": "नामीबिया",
    "NC": "न्यू कैलेडोनिया",
    "NE": "नाइजर",
    "NF": "नोरफॉक द्वीप",
    "NG": "नाइजीरिया",
    "NI": "निकारागुआ",
    "NL": "नीदरलैंड्स",
    "NO": "नॉर्वे",
    "NP": "नेपाल",
    "NR": "नाउरू",
    "NU": "नियू",
    "NZ": "न्यूजीलैंड",
    "OM": "ओमान",
    "PA": "पनामा",
    "PE": "पेरू",
    "PF": "फ्रैंच गुआना",
    "PG": "पापुआ न्यू गिनी",
    "PH": "फिलीपींस",
    "PK": "पाकिस्तान",
    "PL": "पोलैंड",
    "PM": "सेंट पिएरे एंड मिक्वेलॉन",
    "PN": "पिटकैर्न द्वीपसमूह",
    "PR": "पुएर्तो रिको",
    "PS": "फिलिस्तीन राज्य",
    "PT": "पुर्तगाल",
    "PW": "पलाउ",
    "PY": "पराग्वे",
    "QA": "क़तार",
    "RE": "रीयूनियन",
    "RO": "रोमानिया",
    "RS": "सर्बिया",
    "RU": "रूस",
    "RW": "रवांडा",
    "SA": "सऊदी अरब",
    "SB": "सोलोमन द्वीपसमूह",
    "SC": "सेशेल्स",
    "SD": "सूडान",
    "SE": "स्वीडन",
    "SG": "सिंगापुर",
    "SH": "सेंट हेलेना",
    "SI": "स्लोवानिया",
    "SJ": "स्यालबार्ड (स्यालबार्ड एंड जन मावेम)",
    "SK": "स्लोवाकिया",
    "SL": "सियारा लिओन",
    "SM": "सैन मारिनो",
    "SN": "सेनेगल",
    "SO": "सोमालिया",
    "SR": "सूरीनाम",
    "SS": "दक्षिण सूडान",
    "ST": "साओ टॉम एंड प्रिंसिपी",
    "SV": "सल्वाडोर",
    "SX": "सिण्ट मार्टेन",
    "SY": "सीरिया",
    "SZ": "स्वाजीलैंड",
    "TC": "तुर्क एंड कैकोस द्वीपसमूह",
    "TD": "चाड",
    "TF": "फ्रांसीसी दक्षिणी क्षेत्र",
    "TG": "टोगो",
    "TH": "थाईलैंड",
    "TJ": "तज़ाकिस्तान",
    "TK": "टोकेलू",
    "TL": "पूर्वी तिमोर",
    "TM": "तुर्कमेनिस्तान",
    "TN": "ट्यूनीशिया",
    "TO": "टोंगा",
    "TR": "तुर्की",
    "TT": "ट्रिनिडाड एंड टोबैगो",
    "TV": "तुवालू",
    "TW": "ताइवान",
    "TZ": "तंजानिया",
    "UA": "उक्रेन",
    "UG": "उगांडा",
    "UM": "यूएस माइनर आउटलाइंग द्वीपसमूह",
    "US": "यूएसए (संयुक्त राज्य अमेरिका)",
    "UY": "उरुग्वे",
    "UZ": "उजबेकिस्तान",
    "VA": "वेटिकन",
    "VC": "सेंट विंसेंट एंड द ग्रेनेंडाइन्स",
    "VE": "वेनेजुएला",
    "VG": "ब्रितानी वर्जिन द्वीपसमूह",
    "VI": "अमेरिकी वर्जिन द्वीपसमूह",
    "VN": "विएतनाम",
    "VU": "वनातू",
    "WF": "वालीज एंड फुटुना",
    "WS": "पश्चिमी सामोआ",
    "XK": "कोसोवो",
    "YE": "यमन",
    "YT": "मायोते",
    "ZA": "दक्षिण अफ्रीका",
    "ZM": "जाम्बिया",
    "ZW": "जिंबावे"
  }
}
